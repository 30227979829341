import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { useStaticQuery, graphql } from "gatsby";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
          title
          keywords
          description
        }
      }
    }
  `);

  const {
    site: {
      siteMetadata: { keywords, description }
    }
  } = data;

  return (
    <>
      <SEO lang="en" title="VA Software Solutions" keywords={keywords} description={description} />
      <Layout></Layout>
    </>
  );
};

export default IndexPage;
