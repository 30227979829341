import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import MyNavbar from "../views/navbar";
import breakDownAllNodes from "../utils/breakDownAllNodes";
import Footer from "../views//footer";
import Top from "../views/top";
import fileNameToSectionName from "../utils/fileNameToSectionName";
import * as Sections from "../views/sections";

const Layout = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
          title
          keywords
          description
        }
      }
      allMdx(sort: { order: ASC, fields: [fields___directoryName, fields___fileName] }) {
        nodes {
          frontmatter {
            title
            anchor
            brand
            menuText
            copyright
            header
            subheader
            content
            teamMember {
              header
              subheader
              imageFileName
            }
            faq {
              question
              answer
            }
            primaryBtnText
            primaryBtnAnchor
            secondaryBtnText
            secondaryBtnAnchor
            imageFileName
            testimonial {
              name
              role
              quote
            }
            counter {
              text
              count
            }
            services {
              title
              content
              icon
            }
            phone
            email
            address
          }
          fields {
            fileName
            directoryName
          }
          id
          body
        }
      }
    }
  `);

  const {
    site: {
      siteMetadata: { keywords, description }
    },
    allMdx: { nodes }
  } = data;
  const { topNode, navBarNode, anchors, footerNode, sectionsNodes } = breakDownAllNodes(nodes);

  return (
    <div className="main-wrapper">
      <MyNavbar anchors={anchors} frontmatter={navBarNode.frontmatter} />
      <Top frontmatter={topNode.frontmatter} />
      {
        // dynamically import sections
        sectionsNodes.map(({ frontmatter, fields: { fileName } }, ind) => {
          const sectionComponentName = fileNameToSectionName(fileName);
          const SectionComponent = Sections[sectionComponentName];
          return SectionComponent ? (
            <SectionComponent
              key={sectionComponentName}
              frontmatter={frontmatter}
            ></SectionComponent>
          ) : null;
        })
      }
      <Footer frontmatter={footerNode.frontmatter} />
    </div>
  );
};

export default Layout;
